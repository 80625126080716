@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Light'), local('Barlow-Light'), url(/assets/fonts/Barlow-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local('Barlow Regular'), local('Barlow-Regular'), url(/assets/fonts/Barlow-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  src: local('Barlow Medium'), local('Barlow-Medium'), url(/assets/fonts/Barlow-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  src: local('Barlow SemiBold'), local('Barlow-SemiBold'), url(/assets/fonts/Barlow-SemiBold.ttf) format('truetype');
}
