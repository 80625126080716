/* You can add global styles to this file, and also import other style files */
@import "./assets/fonts/barlow.css";

:root {
	--dedicaid-blue-components: 71, 129, 154;
	--dedicaid-blue: rgb(var(--dedicaid-blue-components));
	--dedicaid-red: #e36b78;
	--dedicaid-bgimage: url(/assets/bg.png);
	--dedicaid-report-dark-components: 54,100,120;
	--dedicaid-automl-report-spotlight-color: white;
}

.the-logo {
	content: url(/assets/logo/dedicaid-logo-white.svg);
	min-width: 200px;
}

.dedicaid-theme .header {
	--clr-header-font-color: white;
	--clr-header-bg-color: var(--dedicaid-blue);
	background-image: var(--dedicaid-bgimage);
	background-position: center;
}

.dedicaid-theme  .header .header-nav {
	background: rgba(var(--dedicaid-blue-components), 0.9);
}

:root[cds-theme], body {
	
	--clr-font: 'Barlow', sans-serif !important;
	--clr-body-font: 'Barlow', sans-serif !important;
	--clr-display-font: 'Barlow', sans-serif !important;
	--dedicaid-body-font-size: 18px;
	--dedicaid-secondary-font-size: 0.8rem;
	--dedicaid-color-text-primary: #274754;
	--dedicaid-color-text-secondary: #e36b78;
	--clr-global-font-color: var(--dedicaid-color-text-primary) !important;
	--clr-btn-appearance-form-font-size: var(--dedicaid-secondary-font-size) !important;
	--clr-btn-appearance-standard-font-size: var(--dedicaid-secondary-font-size) !important;
	--clr-accordion-title-font-size: var(--dedicaid-secondary-font-size) !important;
	/*
	--clr-btn-default-border-color: var(--dedicaid-color-text-secondary) !important;
	--clr-btn-default-color: var(--dedicaid-color-text-secondary) !important;
	--clr-btn-default-hover-color: white !important;
	--clr-btn-default-hover-bg-color: #e36b78 !important;
	--clr-btn-primary-bg-color: var(--dedicaid-blue) !important;
	--clr-btn-primary-border-color: var(--dedicaid-blue) !important;
	--clr-p1-color: var(--dedicaid-color-text-primary) !important;*/
	--clr-accordion-active-background-color: linear-gradient(135deg,#ebf1f3,#fff) !important;
	--clr-global-app-background: white !important;
	/*--clr-btn-primary-hover-bg-color: ;*/
}

@media screen and (max-width: 750px), screen and (max-height: 750px) {
	:root {
		/* "rem" is used throughout Clarity. Make the whole UI smaller on mobile */
		font-size: 100%;
	}
}

.branding {
	max-width: 12rem !important;
}

.branding img {
	width: 100%;
}

.branding .title {
	width: 100%; /* needed for Firefox */
}

.header {
	z-index: 1;
}
/*
.dedicaid-theme {
	line-height: 125%;
}

.dedicaid-theme .btn {
	height: unset;
}

body.dedicaid-theme p, body:not([cds-text]).dedicaid-theme p {
	font-size: var(--dedicaid-body-font-size);
}

body.dedicaid-theme, body:not([cds-text]).dedicaid-theme {
	font-size: var(--dedicaid-body-font-size);
}

.dedicaid-theme .nav .nav-link, .dedicaid-theme:not([cds-text]) .nav .nav-link {
	font-size: var(--dedicaid-secondary-font-size);
}

.dropdown-menu .btn, .dropdown-menu .btn-secondary, .dropdown-menu .btn-info, .dropdown-menu .btn-outline, .dropdown-menu .btn-outline-primary, .dropdown-menu .btn-outline-secondary, .dropdown-menu .btn-outline-warning, .dropdown-menu .btn-outline-danger, .dropdown-menu .btn-outline-success, .dropdown-menu .btn-danger, .dropdown-menu .btn-primary, .dropdown-menu .btn-warning, .dropdown-menu .btn-success, .dropdown-menu .btn-link, .dropdown-menu .dropdown-item {
	font-size: var(--dedicaid-secondary-font-size) !important;
}

.dedicaid-theme table th, .dedicaid-theme .clr-input, .dedicaid-theme .login-wrapper .login .login-group .signup {
	font-size: var(--dedicaid-secondary-font-size);
}

.dedicaid-theme table td {
	font-size: var(--dedicaid-body-font-size);
}

.dedicaid-theme .card-text, .dedicaid-theme:not([cds-text]) .card-text {
	font-size: var(--dedicaid-body-font-size);
}

.dedicaid-theme .datagrid .datagrid-column, .dedicaid-theme .datagrid .datagrid-cell, .dedicaid-theme .datagrid-footer {
	font-size: var(--dedicaid-secondary-font-size);
}

.dedicaid-theme h1, .dedicaid-theme:not([cds-text]) h1 {
	font-weight: 600;
	color: var(--dedicaid-color-text-primary);
}

.dedicaid-theme .btn:not(.btn-icon) {
	border-width: 2px;
	border-style: dotted;
	border-radius: 2px;
}

.dedicaid-theme .btn {
	transition: background .2s,color .2s;
	text-transform: uppercase;
    letter-spacing: .2em;
}
*/

.dedicaid-theme .header .branding .title {
	line-height: 55px;
}

@media screen and (max-width: 576px) {
	.dedicaid-theme .main-container .header .branding {
		max-width: none;
	}
}

.grecaptcha-badge { visibility: hidden; }

.main-container {
	height: 100% !important; /* override Clarity's 100hv */
}

.main-container .content-container {
	height: 100%; /* Safari */
	flex-direction: column;
}

.dedicaid-theme .main-container .content-container .content-area {
	overflow-y: hidden;
}

body.full-height {
	height: 100vh;
}

/* Theme bugfix */
.dedicaid-theme .clr-error .clr-select-wrapper:after, .clr-success .clr-select-wrapper:after {
	right: 0.2rem 
}

.dedicaid-theme .datagrid-header {
	z-index: unset;
}

/*
Explanation: clrRange seems to be broken in modals, it produces tons of exceptions and breaks everything when the modal is not shown.
So we resort to using the plain <input type=range> somewhere, but Clarity pollutes the global input[type=range] styling. The below code restores
the default browser behavior.
*/
input[type=range].restore-default {
	height: 16px;
	appearance: auto;
}

input[type=range].restore-default::-webkit-slider-runnable-track {
	background: transparent !important;
}
input[type=range].restore-default::-moz-range-track {
	background: transparent !important;
}

ngx-json-viewer .segment-value {
	white-space: pre;
}

ngx-json-viewer .ngx-json-viewer {
	overflow: scroll !important;
}
